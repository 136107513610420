import React, { useState, useContext, useEffect } from 'react';
import { ApiContext } from '../../context/apiContext';
import { Link } from 'react-router-dom';
import '../orders/orders.scss'
import {formatDate, formatTime, formatCamelCase} from '../../utils/functions';
import ReactJson from 'react-json-view'
import ReactModal from 'react-modal'
import PageTemplate from '../../components/pageTemplate';
import {
    Tag,
    Text,
    IndexTable,
    Button,
    Select,
    TextField,
    Modal,
    useIndexResourceState,
} from '@shopify/polaris';


export default function AdminOrders(){
    const {ordersParents, ordersFetchParents, reImportShopifyOrder, fetchShopifySite} = useContext(ApiContext)
    const [sites, setSites] = useState([]);
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [selectedSite, setSelectedSite] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const {selectedResources, allResourcesSelected, handleSelectionChange} =
    useIndexResourceState(ordersParents);

    const removedKeys = ['failedImportEmailSent','objectId', 'shopifyOrder','createdAt', 'updatedAt','shopifySite','source','shopifyOrderTags']
    let orderKeys = ordersParents.length > 0 ? 
        Object.keys(ordersParents[ordersParents.length-1]).filter(currStr => !removedKeys.includes(currStr)) : []

    let orderTitles = orderKeys.map(currentItem => {
        const nonCamelCase = formatCamelCase(currentItem)
        return {
            id: nonCamelCase,
            title: (
                <Text as='span'>
                    {nonCamelCase}
                </Text>
            )
        }
    })
    orderTitles.push( { id: 'site', title: ( <Text as='span'> Site </Text> ) } )
    orderTitles.push( { id: 'shopify-tags', title: ( <Text as='span'> Shopify Tags </Text> ) } )
    orderTitles.push( { id: 'shopify-order', title: ( <Text as='span'> Shopify Order </Text> ) } )
    orderTitles.push( { id: 'shopify-action', title: ( <Text as='span'> Action </Text> ) } )
    orderTitles.unshift( { id: 'time', title: ( <Text as='span'> Time</Text> ) } )
    orderTitles.unshift( { id: 'date', title: ( <Text as='span'> Date </Text> ) } )
    orderTitles.unshift( { id: 'object-id', title: ( <Text as='span'> Order Id </Text> ) } )

    const orderRows = ordersParents.map((currentObj, index) => {
        const orderRowItems = orderKeys.map((currItm, index) => {
            return (
            <IndexTable.Cell key={index}>
                <Text as="span">
                    {currentObj[currItm]}
                </Text>
            </IndexTable.Cell>)
        })
        return <IndexTable.Row
            id={currentObj.shopifyOrderId}
            key={currentObj.shopifyOrderId}
            position={index}
        >
            <IndexTable.Cell>
                <Link to={`/orders/${currentObj.objectId}`} >
                    <Text fontWeight='bold' as='span'>
                        {currentObj.shopifyOrderNumber}
                    </Text>
                </Link>
            </IndexTable.Cell>
            <IndexTable.Cell>
                <Text as="span" fontWeight='medium' tone="subdued">
                {` ${typeof currentObj.createdAt != "undefined" ? formatDate(currentObj.createdAt) : ' '} `}
                </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
                <Text as="span" fontWeight='medium' tone="subdued">
                {` ${typeof currentObj.createdAt != "undefined" ? formatTime(currentObj.createdAt) : ' '} `}
                </Text>
            </IndexTable.Cell>
            {orderRowItems}
            <IndexTable.Cell>
                <Text as="span">
                    {currentObj.shopifySite}
                </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
                {currentObj.shopifyOrderTags && currentObj.shopifyOrderTags.split(',').map((tag) => {return(<span style={{marginRight:3}}><Tag>{tag}</Tag></span>)})}
            </IndexTable.Cell>
            <IndexTable.Cell>
                <Text>
                    <span className='hover' onClick={() => { handleModalOpen(JSON.stringify(currentObj.shopifyOrder)) }}>
                      <Tag>View</Tag>
                    </span>
                </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
                <Text>
                    {currentObj.failedImportEmailSent && !currentObj.d365OrderId &&
                      <span className='hover' onClick={() => { handleReimportOrder(currentObj.shopifyOrderId, currentObj.shopifySite) }}>
                        <Tag style={{backgroundColor:'red'}}>Re Import Order</Tag>
                      </span>
                    }
                </Text>
            </IndexTable.Cell>
        </IndexTable.Row>
    })

    const [ openModal, setOpenModal ] = useState({
        open: false,
        content: null
    })

    function handleModalClose() {
        setOpenModal(prevOpenModal => {
            return {
                ...prevOpenModal,
                open: false,
                content: null
            }
        })
    }

    function handleModalOpen(text) {
        setOpenModal(prevOpenModal => {
            return {
                ...prevOpenModal,
                open: true,
                content: text
            }
        })
    }

    function handleReimportOrder(shopifyOrderId, site) {
      if (confirm("This action will re-import the order into dynamics, check to make sure the order is not already there. Are you sure you want to continue?") == true) {
        reImportShopifyOrder(shopifyOrderId, site)
        alert("Order Re Imported. It may take up to 10min to appear in D365")
      }
    }

    const handleMasterImport = async () => {
        if (!selectedSite || !orderNumber) {
            setError('Please fill in all fields');
            return;
        }
        setLoading(true);
        setError('');
        
        try {
            // Encode the parameters to handle special characters
            const encodedOrderNumber = encodeURIComponent(orderNumber.replace('#',''));
            const encodedSite = encodeURIComponent(selectedSite);
            
            const response = await fetch(`/getShopifyOrderId?orderNumber=${encodedOrderNumber}&site=${encodedSite}`);
            const data = await response.json();
            if (data) {
                await reImportShopifyOrder(data, selectedSite);
                setImportModalOpen(false);
                setSelectedSite('');
                setOrderNumber('');
                alert("Order Re Imported. It may take up to 10min to appear in D365");
            } else {
                setError(data.message || 'Failed to find order');
            }
        } catch (error) {
            console.error('Import error:', error);
            setError('Failed to process request');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchShopifySite('AALPlaZGKxZqt54BAZmQmNbUQiCUpFIJ')
        ordersFetchParents(true);
        loadSites();
        window.scrollTo(0,0);
    }, []);

    const loadSites = async () => {
        try {
            const response = await fetch('/getSites');
            const sitesData = await response.json();
            if (sitesData && Array.isArray(sitesData)) {
                // Extract shopifySite values from the response
                const siteNames = sitesData
                    .map(site => site)
                    .filter(Boolean); // Remove any undefined or null values
                setSites(siteNames);
            }
        } catch (error) {
            console.error('Error loading sites:', error);
        }
    };

    return(
        <>
            <PageTemplate 
                title='All Sites Orders'
                customPrimaryAction={
                    <Button primary onClick={() => setImportModalOpen(true)}>
                        Master Import
                    </Button>
                }
            >
                <Modal
                    open={importModalOpen}
                    onClose={() => {
                        setImportModalOpen(false);
                        setError('');
                        setSelectedSite('');
                        setOrderNumber('');
                    }}
                    title="Master Import"
                    primaryAction={{
                        content: 'Submit',
                        onAction: handleMasterImport,
                        loading: loading
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: () => {
                                setImportModalOpen(false);
                                setError('');
                                setSelectedSite('');
                                setOrderNumber('');
                            }
                        }
                    ]}
                >
                    <Modal.Section>
                        {error && (
                            <div style={{ marginBottom: '1rem', color: 'red' }}>
                                {error}
                            </div>
                        )}
                        <Select
                            label="Site"
                            options={sites.map(site => ({ label: site, value: site }))}
                            onChange={setSelectedSite}
                            value={selectedSite}
                        />
                        <div style={{ marginTop: '1rem' }}>
                            <TextField
                                label="Order Number"
                                value={orderNumber}
                                onChange={setOrderNumber}
                                autoComplete="off"
                            />
                        </div>
                    </Modal.Section>
                </Modal>

                {openModal.open ?
                    <ReactModal isOpen={openModal.open} onRequestClose={handleModalClose} ariaHideApp={false}>
                        <ReactJson displayDataTypes={false} src={JSON.parse(openModal.content)} />
                    </ReactModal>
                :
                    <IndexTable
                    itemCount={ordersParents.length}
                    selectedItemsCount={
                    allResourcesSelected ? 'All' : selectedResources.length
                    }
                    onSelectionChange={handleSelectionChange}
                    headings={orderTitles}
                    selectable={false}
                    >
                        {orderRows}
                    </IndexTable>
                }
            </PageTemplate>
        </>
    )
} 
